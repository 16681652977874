class LeaveSearch {
    format = (response) => {
        const searchResultMetadata = {
            startIndex: response.searchResultMetaData?.startIndex,
            totalResults: response.searchResultMetaData?.totalResults,
            itemsPerPage: response.searchResultMetaData?.itemsPerPage,
            timeTaken: response.searchResultMetaData?.resultMetadata?.timeTaken,
            links: response.searchResultMetaData?.resultMetaData?._links,
        };
        const LeaveSearch = [];
        response.leaves.forEach((leave) => {
            if (!leave.employee || !leave.leavePeriod) return;
            const postalAddresses = leave.employee.postalAddresses || [null];
            const homeDigitalAddress = leave.employee.digitalAddresses
                ? leave.employee.digitalAddresses.find(address => address.digitalAddressesType === "Home")
                : null;
            const workDigitalAddress = leave.employee.digitalAddresses
                ? leave.employee.digitalAddresses.find(address => address.digitalAddressesType === "Business")
                : null;

            const absences = leave.intermittentAbsenceDetails || [null];
            leave.leavePeriod.forEach((period) => {
                const uniqueProtections = new Set();
                const protections = period.leavePeriodProtection || [null];
                const PeriodClassification =
                    period.leavePeriodClassification || [null];
                PeriodClassification.forEach((PeriodClassification) => {
                    protections.forEach((protection) => {
                        postalAddresses.forEach((address) => {
                            absences.forEach((absence) => {
                                const uniqueKey = `${protection?.protectionId}-${protection?.protectionName}-${protection?.protectionStatusDesc}`;
                                const entry = {
                                    coverageType: leave.coverageType,
                                    ageAtLeaveStart: leave.ageAtLeaveStart,
                                    dateOfBirth: leave.employee.dateOfBirth,
                                    employeeId: leave.employee.employeeId,
                                    homeDigitalAddressText: homeDigitalAddress
                                        ? homeDigitalAddress.digitalAddressText
                                        : null,
                                    workDigitalAddressText: workDigitalAddress
                                        ? workDigitalAddress.digitalAddressText
                                        : null,
                                    earningType: leave.employee.earningType,
                                    managerEmail: leave.employee.managerEmail,
                                    employeeWorkStateCd:
                                        leave.employee.employeeWorkStateCd,
                                    employerLocationName:
                                        leave.employee.employerLocationName,
                                    firstName: leave.employee.firstName,
                                    name: leave.employee.name,
                                    firstHireDt: leave.employee.firstHireDt,
                                    genderAtBirth: leave.employee.genderAtBirth,
                                    jobTitle: leave.employee.jobTitle,
                                    lastName: leave.employee.lastName,
                                    tenureDays: leave.employee.tenureDays,
                                    managerName: leave.employee.managerName,
                                    taxIdentificationNumber:
                                        leave.employee.taxIdentificationNumber,
                                    postalCode: address
                                        ? address.postalCode
                                        : null,
                                    jurisdictionCode: address
                                        ? address.jurisdictionCode
                                        : null,
                                    addressLineOne: address
                                        ? address.addressLineOne
                                        : null,
                                    addressLineTwo: address
                                        ? address.addressLineTwo
                                        : null,
                                    city: address ? address.city : null,
                                    reportedDt: absence
                                        ? absence.reportedDt
                                        : null,
                                    startTime: absence
                                        ? absence.startTime
                                        : null,
                                    intermittentDt: absence
                                        ? absence.intermittentDt
                                        : null,
                                    endTime: absence ? absence.endTime : null,
                                    intermittentAbsenceDisplayName: absence
                                        ? absence.intermittentAbsenceDisplayName
                                        : null,
                                    intermittentAbsenceDurationHoursQty: absence
                                        ? absence.intermittentAbsenceDurationHoursQty
                                        : null,
                                    historicalLeaveInd:
                                        leave.historicalLeaveInd,
                                    lastDateWorked: leave.lastDateWorked,
                                    leaveId: leave.leaveId,
                                    leaveEndDt: leave.leaveEndDt,
                                    leaveCloseReason: leave.leaveCloseReason,
                                    fitForDutyReleaseDt:
                                        leave.fitForDutyReleaseDt,
                                    leavePeriodId: period.leavePeriodId,
                                    periodEndDt: period.periodEndDt,
                                    periodStatusReason:
                                        period.periodStatusReason,
                                    reducedScheduleHours:
                                        period.reducedScheduleHours,
                                    leavePeriodNbr: period.leavePeriodNbr,
                                    actualDeliveryDt: period.actualDeliveryDt,
                                    leaveScheduleType: period.leaveScheduleType,
                                    periodStartDt: period.periodStartDt,
                                    periodStatus: period.periodStatus,
                                    reportGroupName: period.reportGroupName,
                                    reportGroupNumber: period.reportGroupNumber,
                                    leaveReason: leave.leaveReason,
                                    leaveReceivedDt: leave.leaveReceivedDt,
                                    leaveRequestDt: leave.leaveRequestDt,
                                    leaveStartDt: leave.leaveStartDt,
                                    leaveStatus: leave.leaveStatus,
                                    leaveStatusEffectiveDt:
                                        leave.leaveStatusEffectiveDt,
                                    leaveClosedDt: leave.leaveClosedDt,
                                    partTimeRtwDt:
                                        leave.rtwType === "Part Time"
                                            ? leave.rtwDt
                                            : null,

                                    fullTimeRtwDt:
                                        leave.rtwType === "Full Time"
                                            ? leave.rtwDt
                                            : null,

                                    rtwStatus: leave.rtwStatus,
                                    rtwType: leave.rtwType,
                                    rtwLastUpdateDt: leave.rtwLastUpdateDt,
                                    relatedClaimLinkedInd:
                                        leave.relatedClaimLinkedInd,
                                    claimId: leave.relatedClaimDetails.claimId,
                                    groupAgreementId:
                                        leave.relatedClaimDetails
                                            .groupAgreementId,
                                    hireDt: leave.employee
                                        ? leave.employee.hireDt
                                        : null,
                                    divisionId:
                                        leave.relatedClaimDetails.divisionId,
                                    policyName:
                                        leave.relatedClaimDetails.policyName,
                                    divisionName:
                                        leave.relatedClaimDetails.divisionName,
                                    disabilityBeginDt:
                                        leave.relatedClaimDetails
                                            .disabilityBeginDt,
                                    relatedStdClaimId:
                                        leave.relatedClaimDetails.claimId,
                                    relatedStdClaimStatus:
                                        leave.relatedClaimDetails.claimStatus,
                                    relatedStdClaimStatusReason:
                                        leave.relatedClaimDetails
                                            .claimStatusReason,
                                    relatedStdDivisionName:
                                        leave.relatedClaimDetails.divisionName,
                                    relatedStdDivisionId:
                                        leave.relatedClaimDetails.divisionId,
                                    relatedStdPolicyName:
                                        leave.relatedClaimDetails.policyName,
                                    relatedStdPolicyNumber:
                                        leave.relatedClaimDetails
                                            .groupAgreementId,
                                    relatedStdMaxDurationDate:
                                        leave.relatedClaimDetails
                                            .claimBenefitEndDt,
                                    relatedStdClaimDuration:
                                        leave.relatedClaimDetails.claimDuration,
                                    personDisplayName:
                                        leave.leaveSpecialist.personDisplayName,

                                    classificiationTypeDesc:
                                        PeriodClassification
                                            ? PeriodClassification.classificiationTypeDesc
                                            : null,
                                };
                                if (!uniqueProtections.has(uniqueKey)) {
                                    entry.protectionId =
                                        protection?.protectionId;
                                    entry.protectionStatusDesc =
                                        protection?.protectionStatusDesc;
                                    entry.protectionName =
                                        protection?.protectionName;
                                    entry.hoursAvailable =
                                        protection?.protectionHours?.hoursAvailable;
                                    entry.hoursTaken =
                                        protection?.protectionHours?.hoursTaken;
                                    uniqueProtections.add(uniqueKey);
                                }
                                LeaveSearch.push(entry);
                            });
                        });
                    });
                });
            });
        });
        return { searchResultMetadata, LeaveSearch };
    };
}

export default new LeaveSearch();
